.toast-container {
  @apply tw-fixed tw-bottom-4 tw-left-4 tw-right-4 tw-z-[9999] tw-flex tw-flex-col tw-gap-2 sm:tw-left-auto sm:tw-w-full sm:tw-max-w-[400px];
}

.tw-toast {
  @apply tw-font-text tw-flex tw-flex-col tw-gap-2 tw-rounded-md tw-border tw-border-border tw-bg-background tw-p-4 tw-text-foreground tw-shadow-lg;

  .toast-title {
    @apply tw-text-sm tw-font-bold;
  }

  .toast-message {
    @apply tw-text-sm;
  }

  &.tw-toast-success,
  &.tw-toast-info,
  &.tw-toast-error,
  &.tw-toast-warning {
    @apply tw-relative tw-pl-9 before:tw-absolute before:tw-left-2 before:tw-top-3 before:tw-h-8 before:tw-w-8;

    &:before {
      font-family: "feather";
      speak: none;
      @apply tw-text-xl;
    }
  }

  &.tw-toast-success {
    @apply tw-border-green-600 tw-bg-green-50 tw-text-green-700;

    &:before {
      content: "\e83f";
    }
  }

  &.tw-toast-error {
    @apply tw-border-red-600 tw-bg-red-50 tw-text-red-700;

    &:before {
      content: "\e8f6";
    }
  }

  &.tw-toast-warning {
    @apply tw-border-amber-600 tw-bg-amber-50 tw-text-amber-700;

    &:before {
      content: "\e81c";
    }
  }

  &.tw-toast-info:before {
    content: "\e87f";
  }
}
