.tw-checkbox {
  @apply tw-relative tw-flex tw-items-start tw-gap-2;

  input[type="checkbox"] {
    @apply tw-absolute tw-right-[200%] tw-opacity-0;

    &:focus,
    &:checked {
      & + label,
      & + .tw-checkbox-label {
        @apply tw-text-blue-600;

        &:before {
          @apply tw-outline tw-outline-blue-600;
        }
      }
    }

    &:disabled {
      & + label,
      & + .tw-checkbox-label {
        @apply tw-cursor-not-allowed tw-text-muted-foreground;

        &:before {
          @apply tw-opacity-50 tw-grayscale;
        }
      }
    }

    &:focus-visible {
      & + label,
      & + .tw-checkbox-label {
        @apply tw-bg-yellow-100;
      }
    }

    &:checked {
      & + label,
      & + .tw-checkbox-label {
        @apply tw-font-semibold;

        &:after {
          @apply tw-scale-50 tw-opacity-100 tw-transition tw-duration-300;
        }
      }
    }
  }

  label,
  .tw-checkbox-label {
    @apply tw-relative tw-flex;

    &:before {
      content: "";
      @apply tw-mr-2 tw-flex tw-h-6 tw-w-6 tw-shrink-0 tw-rounded-sm tw-border tw-border-blue-600 tw-bg-background tw-transition-all;
    }

    &:after {
      content: "";
      @apply tw-absolute tw-left-0 tw-top-0 tw-h-6 tw-w-6 tw-scale-[2] tw-rounded-sm tw-bg-blue-600 tw-opacity-0 tw-ease-out;
    }
  }

  &.tw-checkbox-brand {
    input[type="checkbox"] {
      &:focus,
      &:checked {
        & + label {
          @apply tw-text-brand-main;

          &:before {
            @apply tw-outline-brand-main;
          }
        }
      }
    }

    label {
      @apply tw-relative;

      &:before {
        content: "";
        @apply tw-border-brand-main;
      }

      &:after {
        content: "";
        @apply tw-bg-brand-main;
      }
    }
  }
}
