body {
  @apply tw-min-h-screen tw-bg-gradient-to-br tw-from-white tw-to-brand-main/20;

  &.webinar {
    @apply tw-from-brand-main/10 tw-to-brand-main/20;
  }
}

.app-root {
  @apply tw-flex tw-min-h-dvh tw-flex-col;
  & > app-full-layout {
    @apply tw-flex tw-min-h-dvh tw-flex-1 tw-flex-col;
  }
}

.app-sidebar {
  @apply tw-border-r tw-border-border tw-shadow-none;
}

.pace {
  @apply tw-hidden;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
  @apply tw-bg-brand-main/10 tw-text-brand-main;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected,
.ng-dropdown-panel
  .ng-dropdown-panel-items
  .ng-option.ng-option-selected.ng-option-marked {
  @apply tw-bg-brand-main tw-text-white;
}

* > br {
  @apply tw-hidden;
}

uplift-home-card {
  @apply tw-grid;
}

app-content-layout,
app-uplift-user-session-course,
up-orbit-webinar {
  @apply tw-flex tw-min-h-dvh tw-flex-col;
}

app-home-page {
  @apply tw-flex tw-flex-col tw-flex-1;
}

.progress {
  @apply tw-bg-muted;

  .progress-bar {
    @apply tw-bg-gradient-to-r tw-from-blue-500 tw-to-teal-300;
  }
}

.card {
  box-shadow: none !important;
}

.apexcharts-menu-icon {
  display: none;
  pointer-events: none;
}
