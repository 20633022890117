form {
  .tw-form-section {
    @apply tw-font-text;
  }

  label {
    @apply tw-m-0 tw-inline-flex tw-cursor-pointer hover:tw-text-brand-main;
  }

  input[type="text"],
  input[type="textarea"],
  input[type="password"],
  input[type="email"],
  input[type="url"],
  input[type="date"],
  input[type="number"],
  textarea {
    @apply tw-rounded-md tw-border tw-border-input-border;

    &.is-invalid {
      @apply tw-border-red-500;
    }

    &.is-valid {
      @apply tw-border-green-500;
    }
  }
  textarea {
    @apply tw-max-h-[50vh] tw-min-h-10;
  }
}
