$border-radius: 3px;
$font-size: 16px;
$animation-duration: 0.6s;

.animated {
  animation-fill-mode: both;
  animation-duration: $animation-duration;
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  -ms-animation-fill-mode: both;
  -o-animation-fill-mode: both;
  -webkit-animation-duration: $animation-duration;
  -moz-animation-duration: $animation-duration;
  -ms-animation-duration: $animation-duration;
  -o-animation-duration: $animation-duration;
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.fade-in-up {
  animation-name: fadeInUp;
  -webkit-animation-name: fadeInUp;
  -moz-animation-name: fadeInUp;
  -o-animation-name: fadeInUp;
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.fade-in-down {
  animation-name: fadeInDown;
  -webkit-animation-name: fadeInDown;
  -moz-animation-name: fadeInDown;
  -o-animation-name: fadeInDown;
}

@keyframes fadeInRight {
  0% {
    opacity: 0;
    transform: translateX(-20px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.fade-in-right {
  animation-name: fadeInRight;
  -webkit-animation-name: fadeInRight;
  -moz-animation-name: fadeInRight;
  -o-animation-name: fadeInRight;
}

@keyframes fadeInLeft {
  0% {
    opacity: 0;
    transform: translateX(20px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.fade-in-left {
  animation-name: fadeInLeft;
  -webkit-animation-name: fadeInLeft;
  -moz-animation-name: fadeInLeft;
  -o-animation-name: fadeInLeft;
}

div.hopscotch-bubble {
  @apply tw-font-text tw-rounded-md tw-border-0 tw-bg-neutral-600 tw-text-background tw-drop-shadow-2xl;
  font-size: $font-size;
  position: absolute;
  z-index: 20;
  box-sizing: content-box;
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
  -moz-background-clip: padding;
  -webkit-background-clip: padding;
  background-clip: padding-box;

  & * {
    box-sizing: content-box;
    -moz-box-sizing: content-box;
    -webkit-box-sizing: content-box;
  }

  &.animate {
    transition:
      top 1s ease-in-out,
      left 1s ease-in-out;
    -moz-transition:
      top 1s ease-in-out,
      left 1s ease-in-out;
    -ms-transition:
      top 1s ease-in-out,
      left 1s ease-in-out;
    -o-transition:
      top 1s ease-in-out,
      left 1s ease-in-out;
    -webkit-transition:
      top 1s ease-in-out,
      left 1s ease-in-out;
  }

  &.invisible {
    opacity: 0;
  }

  &.hide,
  & .hide,
  & .hide-all {
    display: none;
  }

  h3 {
    @apply tw-font-text tw-font-black;
    color: #fff;
    font-size: 16px;
    margin: -1px 1rem 0 0;
    line-height: normal;
    padding: 0;
  }

  .hopscotch-bubble-container {
    padding: 15px;
    position: relative;
    text-align: left;
    -webkit-font-smoothing: antialiased;
  }

  .hopscotch-content {
    font-weight: normal;
    margin: -5px 0 11px;
    padding-top: 8px;
  }

  .hopscotch-bubble-content {
    margin: 0 0 0 40px;

    .no-number & {
      margin: 0;
    }
  }

  .hopscotch-bubble-close {
    appearance: none;
    border: 0;
    color: #000;
    cursor: pointer;
    display: block;
    padding: 8px;
    position: absolute;
    text-decoration: none;
    text-indent: -9999px;
    width: 8px;
    height: 8px;
    top: 0;
    right: 0;

    &:before {
      position: absolute;
      content: "x";
      text-indent: 0;
      top: 0;
      right: 0;
      color: #fff;
      @apply tw-flex tw-size-8 tw-items-center tw-justify-center tw-text-lg tw-font-bold;
    }

    &.hide,
    &.hide-all {
      display: none;
    }
  }

  .hopscotch-bubble-number {
    background: #fff;
    color: #000;
    border-radius: 50%;
    float: left;
    font-size: 17px;
    font-weight: bold;
    text-align: center;
    width: 30px;
    height: 30px;
    @apply tw-flex tw-items-center tw-justify-center tw-text-neutral-600;
  }

  .hopscotch-bubble-arrow-container {
    position: absolute;
    width: 34px;
    height: 34px;

    .hopscotch-bubble-arrow,
    .hopscotch-bubble-arrow-border {
      width: 0;
      height: 0;
    }

    &.up {
      top: -18px;
      left: 10px;

      .hopscotch-bubble-arrow {
        border-bottom: 17px solid;
        border-left: 17px solid transparent;
        border-right: 17px solid transparent;
        position: relative;
        top: -14px;
        @apply tw-relative tw-top-[1px] tw-border-b-neutral-600;
      }

      .hopscotch-bubble-arrow-border {
        @apply tw-hidden;
      }
    }

    &.down {
      bottom: -34px;
      left: 10px;

      .hopscotch-bubble-arrow {
        border-top: 17px solid;
        border-left: 17px solid transparent;
        border-right: 17px solid transparent;
        position: relative;
        top: -20px;
        @apply tw-border-t-neutral-600;
      }

      .hopscotch-bubble-arrow-border {
        border-top: 17px solid #000000;
        border-top: 17px solid;
        border-left: 17px solid transparent;
        border-right: 17px solid transparent;
        @apply tw-border-t-neutral-600;
      }
    }

    &.left {
      top: 10px;
      left: -17px;

      .hopscotch-bubble-arrow {
        border-bottom: 17px solid transparent;
        border-right: 17px solid;
        border-top: 17px solid transparent;
        position: relative;
        left: 4px;
        top: -34px;
        @apply tw-border-r-neutral-600;
      }

      .hopscotch-bubble-arrow-border {
        border-right: 17px solid #000000;
        border-bottom: 17px solid transparent;
        border-top: 17px solid transparent;
        @apply tw-border-r-neutral-600;
      }
    }

    &.right {
      top: 10px;
      right: -34px;

      .hopscotch-bubble-arrow {
        border-bottom: 17px solid transparent;
        border-left: 17px solid;
        border-top: 17px solid transparent;
        position: relative;
        left: -3px;
        top: -34px;
        @apply tw-border-l-neutral-600;
      }

      .hopscotch-bubble-arrow-border {
        border-left: 17px solid #000000;
        border-left: 17px solid;
        border-bottom: 17px solid transparent;
        border-top: 17px solid transparent;
        @apply tw-border-l-neutral-600;
      }
    }
  }

  .hopscotch-actions {
    margin: 10px 0 0;
    text-align: right;
  }
}

div.hopscotch-bubble .hopscotch-nav-button {
  font-weight: bold;
  border-width: 1px;
  border-style: solid;
  cursor: pointer;
  margin: 0;
  overflow: visible;
  text-decoration: none !important;
  width: auto;
  padding: 0 10px;
  height: 26px;
  line-height: 24px;
  font-size: 14px;
  display: inline-block;
  vertical-align: middle;
  box-sizing: border-box;
  @apply tw-rounded-md;
}
