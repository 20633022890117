@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --border: 0 0% 89.8%;
    --background: 0 0% 100%;
    --foreground: 0 0% 3.9%;
    --muted: 0 0% 96.1%;
    --muted-foreground: 0 0% 40%;
    --brand-main: 221 83% 53%;
    --input-border: 0 0% 80%;
  }
}

@import "./tailwind-components/archwizard";
@import "./tailwind-components/badge";
@import "./tailwind-components/block";
@import "./tailwind-components/button";
@import "./tailwind-components/card";
@import "./tailwind-components/checkbox";
@import "./tailwind-components/dragula";
@import "./tailwind-components/editor";
@import "./tailwind-components/fix-apex";
@import "./tailwind-components/fonts";
@import "./tailwind-components/form";
@import "./tailwind-components/link";
@import "./tailwind-components/modal";
@import "./tailwind-components/page-title";
@import "./tailwind-components/popover";
@import "./tailwind-components/radio";
@import "./tailwind-components/sortable";
@import "./tailwind-components/swal";
@import "./tailwind-components/toaster";
@import "./tailwind-components/tooltip";
@import "./tailwind-components/hopscotch";
