@font-face {
  font-family: "Nunito Sans";
  src: url("/assets/fonts/Nunito-VariableFont_wght.ttf") format("truetype");
}

@font-face {
  font-family: "Hand";
  src: url("/assets/fonts/GloriaHallelujah-Regular.ttf") format("truetype");
}

.tw-font-text {
  font-family: "Nunito Sans", Arial, serif;
}

.tw-font-text-bold {
  font-family: "Nunito Sans", Arial, serif;
  font-weight: 700;
}

.tw-font-handwritten {
  font-family: "Hand", Arial, serif;
  font-weight: 700;
}

.tw-prose {
  b, a {
    @apply tw-text-brand-main;
  }

  li p {
    @apply tw-my-0;
  }

  i {
    @apply tw-text-xs tw-not-italic tw-text-muted-foreground;
  }

  h1, h2, h3, h4, h5, h6 {
    @apply tw-font-text;
  }

  h3 {
    @apply tw-font-bold;
  }
}

* + br {
  @apply tw-absolute tw-hidden;
}
