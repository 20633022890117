.tw-radio {
  @apply tw-relative tw-flex tw-items-start tw-gap-2 tw-transition-colors hover:tw-bg-neutral-50 has-[input:disabled]:tw-pointer-events-none has-[input:checked]:tw-bg-blue-600/5 tw-mb-0;

  input[type="radio"] {
    @apply tw-absolute tw-right-[calc(2*100svw)];

    &:focus,
    &:checked {
      & + span {
        @apply tw-text-blue-600;

        &:before {
          @apply tw-outline tw-outline-blue-600;
        }
      }
    }

    &:disabled {
      & + span {
        @apply tw-cursor-not-allowed tw-text-muted-foreground;

        &:before {
          @apply tw-opacity-50 tw-grayscale;
        }
      }
    }

    &:focus-visible {
      & + span {
        @apply tw-bg-yellow-100;
      }
    }

    &:checked {
      & + span {
        @apply tw-font-semibold;

        &:after {
          @apply tw-scale-50 tw-opacity-100 tw-transition tw-duration-300;
        }
      }
    }
  }

  & > span {
    @apply tw-relative tw-inline-flex tw-flex-1;

    &:before {
      content: "";
      @apply tw-mr-2 tw-flex tw-h-6 tw-w-6 tw-shrink-0 tw-rounded-full tw-border tw-border-blue-600 tw-bg-background tw-transition-all;
    }

    &:after {
      content: "";
      @apply tw-absolute tw-left-0 tw-top-0 tw-h-6 tw-w-6 tw-scale-[2] tw-rounded-full tw-bg-blue-600 tw-opacity-0 tw-ease-out;
    }
  }
}
