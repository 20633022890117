/* You can add global styles to this file, and also import other style files */

div.locked-quest-mask{
  background-color: rgba(0,0,0,0.2); /* Black background with opacity */
}

div.locked-protocol-mask{
  background-color: #e5e5e5; /* Black background with opacity */
  opacity: 1;

  div.card {
    background-color: rgba(0,0,0,0.15);

    img.card-img-top {
      opacity: 0.5;
    }
  }
}


div.unlocked-protocol-mask{
  background-color: #e5e5e5; /* Black background with opacity */
  opacity: 1;
}

div.locked-quest-mask{
  background-color: rgba(0,0,0,0.2); /* Black background with opacity */
}

div.locked-protocol-mask{
  background-color: #e5e5e5; /* Black background with opacity */
  opacity: 1;

  div.card {
    background-color: #d3d3d3;

    img.card-img-top {
      opacity: 0.5;
    }
  }
}


div.unlocked-protocol-mask{
  background-color: #25efcb; /* Black background with opacity */
  opacity: 1;
}

img.lighten-image {
  opacity: 0.5;
}

body {
  overscroll-behavior-y: none;
}

// TODO: temporary fix after @ng-bootstrap package upgrade need to check later
.modal-open .modal{
  z-index: 1060 !important;
}

@import 'node_modules/@swimlane/ngx-datatable/index.css';
@import 'node_modules/@swimlane/ngx-datatable/themes/material.css';
@import 'node_modules/@swimlane/ngx-datatable/assets/icons.css';
// @import 'ngx-toastr/toastr';
@import './assets/sass/tailwind-config';
