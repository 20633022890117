.tw-card {
  @apply tw-font-text tw-rounded-md tw-border tw-border-border tw-bg-background tw-p-4 tw-shadow md:tw-p-6;

  &.tw-card-gradient {
    border-image: linear-gradient(to right, blue, purple) 1;
    border-width: 3px;
    border-radius: 6px;
  }
}

.tw-card-header {
  @apply tw-font-text tw-mb-2 tw-text-lg tw-font-black tw-leading-tight tw-text-brand-main md:tw-text-xl tw-min-h-[65px] tw-flex tw-items-center;
}
