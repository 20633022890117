.tw-button {
  @apply tw-inline-flex tw-rounded-md tw-bg-brand-main tw-p-0 tw-text-brand-main hover:tw-text-brand-main;

  & > span {
    @apply tw-inline-flex tw-h-full tw-w-full tw-items-center tw-justify-center tw-rounded-md tw-border-2 tw-border-brand-main tw-bg-background tw-px-4 tw-py-2 tw-font-semibold tw-transition-transform tw-ease-in-out;
  }

  &:hover:not(:disabled),
  &:focus-visible:not(:disabled) {
    & > span {
      @apply tw-text-brand-main md:-tw-translate-y-1 md:tw-translate-x-1;
    }
  }

  &:focus-visible:not(:disabled) > span {
    @apply tw-bg-yellow-100;
  }

  &:disabled {
    @apply tw-cursor-not-allowed tw-text-muted-foreground;

    & > span {
      @apply tw-border-muted tw-bg-muted;
    }
  }
}

.tw-button-success {
  @apply tw-bg-green-500 tw-text-green-600;

  & > span {
    @apply tw-border-green-500;
  }
}

.tw-button-ghost {
  @apply tw-flex tw-aspect-square tw-w-8 tw-items-center tw-justify-center tw-rounded-md tw-bg-background tw-text-brand-main tw-transition-colors hover:tw-bg-brand-main/5 focus-visible:tw-bg-brand-main/5 [&[aria-expanded=true]]:tw-bg-brand-main/5;
}
