html:has(.swal2-modal) {
  overflow: hidden;
}

.swal2-container {
  @apply tw-fixed tw-inset-0 tw-z-[999] tw-flex tw-items-center tw-justify-center tw-overflow-y-auto tw-bg-black/60 tw-p-4 tw-backdrop-blur;
}

.swal2-modal {
  @apply tw-font-text tw-flex tw-w-full tw-flex-col tw-rounded-md tw-border tw-border-border tw-bg-background tw-p-4 sm:tw-max-w-[500px] md:tw-p-8;
}

.swal2-header {
  @apply tw-mb-4;

  h2 {
    @apply tw-font-text tw-text-xl tw-font-bold tw-text-brand-main md:tw-text-2xl;
  }
}

.swal2-content {
  @apply tw-mb-6;
}

.swal2-actions {
  @apply tw-flex tw-flex-col tw-gap-4 md:tw-flex-row md:tw-items-center md:tw-justify-end;

  button {
    @apply tw-inline-flex tw-items-center tw-justify-center tw-rounded-md tw-border-2 tw-border-brand-main tw-px-3 tw-py-1.5 tw-font-semibold tw-text-brand-main tw-transition-[colors,transform] hover:tw-bg-brand-main/90 focus-visible:tw-bg-yellow-100 focus-visible:tw-text-foreground active:tw-bg-yellow-100 active:tw-text-brand-main;
  }

  .swal2-confirm {
    @apply tw-bg-brand-main tw-text-background md:tw-order-3;
  }

  .swal2-deny {
    @apply md:tw-order-2;
  }

  .swal2-cancel,
  .swal2-deny {
    @apply hover:tw-bg-background md:tw-order-1;
  }
}

.swal2-show {
  @apply tw-animate-modalShow;
}

.swal2-hide {
  @apply tw-animate-modalHide;
}
