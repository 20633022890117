.steps-indicator {
  @apply tw-relative tw-hidden tw-justify-between tw-py-4 before:tw-absolute before:tw-left-1/2 before:tw-top-1/2 before:tw-h-[1px] before:tw-w-1/2 before:-tw-translate-x-1/2 before:-tw-translate-y-1/2 before:tw-bg-border sm:tw-flex;

  li {
    @apply tw-pointer-events-none tw-relative tw-flex-1 tw-text-center tw-text-muted-foreground;

    a {
      @apply tw-inline-flex tw-items-center tw-justify-center tw-rounded-full tw-border tw-bg-background tw-px-3 tw-py-1 tw-text-sm;
    }

    &.current {
      @apply tw-font-bold tw-text-brand-main;

      a {
        @apply tw-border-brand-main;
      }
    }

    &.done {
      @apply tw-pointer-events-auto tw-font-bold tw-text-green-600;

      a {
        @apply tw-border-green-500;
      }
    }
  }
}
