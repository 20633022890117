.modal-backdrop {
  @apply tw-bg-black/60 tw-opacity-100 tw-backdrop-blur;

  &.show {
    opacity: 1;
  }
}

.modal-content {
  @apply tw-w-full tw-p-4 md:tw-p-8;

  header {
    @apply tw-font-text tw-mb-4 tw-text-xl tw-font-bold tw-text-brand-main md:tw-text-2xl;
  }

  button {
    @apply tw-inline-flex tw-items-center tw-justify-center tw-rounded-md tw-border-2 tw-border-brand-main tw-px-3 tw-py-1.5 tw-font-semibold tw-text-brand-main tw-transition-[colors,transform] hover:tw-bg-brand-main/90 focus-visible:tw-bg-yellow-100 focus-visible:tw-text-foreground active:tw-bg-yellow-100 active:tw-text-brand-main;

    &.button-primary {
      @apply tw-order-1 tw-bg-brand-main tw-text-background md:tw-order-2;
    }

    &.button-outline {
      @apply tw-order-2 hover:tw-bg-background md:tw-order-1;
    }

    &:disabled {
      @apply tw-cursor-not-allowed tw-border-muted-foreground tw-bg-background tw-text-muted-foreground;
    }
  }
}

.modal {
  &.show {
    @apply tw-animate-modalShow;
  }

  &.hide {
    @apply tw-animate-modalHide;
  }
}

.modal-dialog {
  margin-block: 0 !important;

  &.modal-dialog-centered {
    min-height: 100%;
  }
}

.tw-modal-md {
  @apply tw-max-w-screen-md;
}

.tw-modal-lg {
  @apply tw-max-w-screen-lg;
}

.tw-modal-xl {
  @apply tw-max-w-screen-xl;
}
