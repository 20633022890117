.tw-link {
  @apply tw-inline-flex tw-items-center tw-font-semibold tw-text-brand-main;

  &:hover,
  &:focus-visible,
  &:active {
    @apply tw-text-brand-main;

    & > span {
      @apply tw-underline tw-underline-offset-2;
    }
  }

  &:visited {
    @apply tw-text-brand-main;
  }

  &:focus-visible {
    @apply tw-bg-yellow-100;
  }
}
