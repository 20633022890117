.tw-popover {
  @apply tw-font-text tw-max-w-sm tw-rounded-md tw-border-2 tw-border-brand-main tw-bg-background tw-drop-shadow-sm md:tw-w-full;

  .popover-header {
    @apply tw-font-text tw-border-0 tw-bg-background tw-pb-0 tw-text-sm tw-font-bold tw-text-brand-main;
  }

  .popover-body {
    @apply tw-pt-1 tw-font-semibold;
  }

  .popover-arrow {
    @apply -tw-mt-2 tw-ml-8 tw-h-4 tw-w-4 tw-rotate-45 tw-border-2 tw-border-brand-main tw-border-b-transparent tw-border-r-transparent tw-bg-background;
    rotate: 45deg;
  }

  a {
    @apply tw-inline-flex tw-truncate tw-max-w-full;
  }
}

.tw-popover-sm {
  @apply tw-max-w-[250px];
}

.tw-popover-md {
  @apply tw-max-w-[320px];
}


.bs-popover-bottom-end {
  .popover-arrow {
    transform: translate3d(0, 0, 0) !important;
    left: auto !important;
    right: 0;
    @apply tw-ml-0 tw-mr-8;
  }
}

.bs-popover-top {
  .popover-arrow {
    transform: translate3d(0, 0, 0) !important;
    top: auto !important;
    left: 50% !important;
    rotate: 225deg;
    @apply -tw-bottom-2 -tw-ml-2;
  }
}

.bs-popover-top-start {
  .popover-arrow {
    transform: translate3d(0, 0, 0) !important;
    left: 0 !important;
    @apply tw-ml-8;
  }
}

.tw-popover:hover {
  @apply tw-z-[1061];
}
