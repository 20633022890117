/* in-flight clone */
.gu-mirror {
  position: fixed !important;
  margin: 0 !important;
  z-index: 9999 !important;
  pointer-events: none;
  @apply tw-opacity-90;

  .tw-card {
    @apply tw-bg-yellow-50 tw-drop-shadow-xl;
  }
}

/* high-performance display:none; helper */
.gu-hide {
  left: -9999px !important;
}

/* added to mirrorContainer (default = body) while dragging */
.gu-unselectable {
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}

/* added to the source element while its mirror is dragged */
.gu-transit .tw-card {
  @apply tw-bg-brand-main/5 tw-opacity-50;
}

[data-component="draggable"] .tw-card {
  @apply tw-transition-all;
}
