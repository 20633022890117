.tooltip-inner {
  @apply tw-font-text tw-max-w-full tw-rounded-md tw-border tw-border-border tw-bg-background tw-bg-opacity-100 tw-text-sm tw-text-foreground tw-opacity-100 tw-drop-shadow-lg;
  opacity: 100% !important;
}

.tw-large-tooltip {
  @apply tw-w-[320px];
  opacity: 100% !important;
}

.tw-opaque-tooltip {
  opacity: 100% !important;
}
